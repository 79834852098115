import React, { useState } from "react"

import { Container, Form, Col, FormFile, Button, Row } from "react-bootstrap"
import { Link } from "gatsby"
import { useForm } from "react-hook-form"
import "react-nice-dates/build/style.css"
import PropTypes from "prop-types"

import FormData from "form-data"
import axios from "axios"

import Header from "../../components/Header"
import Footer from "../../components/Footer"
import ClarificaitonModal from "../../components/ClarificationModal"

import { useGlobalState } from "../../global/global"
import { getLanguageFile } from "../../global/langauge.helper"
import { useEffect } from "react"

import "./styles.scss"

import UPLOAD_IMG from "../../../assets/images/fileUpload.png"
import REMOVE_FILE from "../../../assets/images/removeFile.png"
import JOB_APPLY_ICON from "../../../assets/images/jobApplyVector.png"

const API_URL = "/mail/job-applications"
// eslint-disable-next-line no-useless-escape
const isUrlRegex = /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/

const JobApplyPage = ({ location }) => {
  const [sendedCardName, setSendedCardName] = useState("softwareEngineer")
  useEffect(() => {
    if (typeof window !== "undefined") {
      setSendedCardName(
        location.state.selectedCardName !== null
          ? location.state.selectedCardName
          : "softwareEngineer"
      )
    }
  }, [])

  const [state] = useGlobalState()
  const [isUpload, setIsUpload] = useState(false)
  const [selectedFileList, setSelectedFileList] = useState([])
  const [selectedFileListTag, setSelectedFileListTag] = useState("")
  const [applyStatusCode, setApplyStatusCode] = useState()
  const [showModal, setShowModal] = useState(false)
  const { register, handleSubmit, errors } = useForm()

  const clarificaitonText = "Aydınlatma metni 'ni okudum, kabul ediyorum."

  const handleClickClarificaitonText = word => {
    console.log(word)
    setShowModal(true)
  }

  const renderClarificaitonText = () =>
    clarificaitonText.split(" ").map(word =>
      // eslint-disable-next-line react/jsx-key

      word === "Aydınlatma" || word === "metni" ? (
        word === "Aydınlatma" ? (
          <span
            className="clarificaiton-text"
            onClick={() => handleClickClarificaitonText(word)}
          >
            {word}{" "}
          </span>
        ) : (
          <span
            className="clarificaiton-text"
            onClick={() => handleClickClarificaitonText(word)}
          >
            {word}
          </span>
        )
      ) : (
        <span
          className="clarificaiton-text-rest"
          onClick={() => handleClickClarificaitonText(word)}
        >
          {word}{" "}
        </span>
      )
    )

  const onSubmit = data => {
    let formData = new FormData()
    Object.keys(data).forEach(key => {
      switch (key) {
        case "personalWebsite":
        case "facebook":
        case "twitter":
        case "linkedin": {
          if (key.length !== 0) {
            data[key] = undefined
          }
          break
        }
        case "dateOfBirth": {
          let date
          try {
            const dateParts = data[key].split("/")
            date = new Date(
              `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`
            ).toISOString()
          } catch (error) {
            date = new Date(`1990-01-01`).toISOString()
          }
          data[key] = date
          break
        }
        default: {
          break
        }
      }

      if (data[key]) {
        formData.append(key, data[key])
      }
    })

    formData.append("jobTitle", "Software Engineer")
    formData.append(
      "fullname",
      data["firstName"].concat(" ").concat(data["lastName"])
    )

    selectedFileList.forEach(file => {
      formData.append("files", file)
    })

    axios
      .post(API_URL, formData)
      .then(response => setApplyStatusCode(response.status))
      .catch(error => console.log(error.response))
  }

  const onFileUpload = event => {
    const filesArray = selectedFileList
    let filesTag = selectedFileListTag
    event.target.files.length !== 0
      ? (filesTag =
          selectedFileListTag === ""
            ? selectedFileListTag.concat(event.target.files[0].name)
            : selectedFileListTag
                .concat(", ")
                .concat(event.target.files[0].name))
      : null

    setSelectedFileListTag(filesTag)
    filesArray.push(event.target.files[0])
    setSelectedFileList(filesArray)
  }

  return (
    <>
      <Container className="apply-main-container">
        <Header />
        <Col className="col-lg-8 offset-lg-2">
          {applyStatusCode === 200 ? (
            <Container className="succesfull-job-apply-container">
              <Row className="succesfull-job-apply-row">
                <img
                  className="successfull-job-apply-icon"
                  src={JOB_APPLY_ICON}
                />
                <h4 className="succesfull-job-apply-title">
                  {getLanguageFile(state.language).JobApply.succesfullApply}
                </h4>
                <p className="succesfull-job-apply-description">
                  {
                    getLanguageFile(state.language).JobApply
                      .succesfullApplyDescription
                  }
                </p>
                <Link style={{ textAlign: "center" }} to="/">
                  <Button className="succesfull-job-apply-button">
                    {getLanguageFile(state.language).JobApply.homeButton}
                  </Button>
                </Link>
              </Row>
            </Container>
          ) : (
            <Container>
              <Container className="form-container">
                <Col>
                  <h4 className="job-title">
                    {
                      getLanguageFile(state.language).JobApply[
                        `${
                          sendedCardName === null
                            ? "softwareEngineer"
                            : sendedCardName
                        }Title`
                      ]
                    }
                  </h4>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Row>
                      <Col>
                        <Form.Control
                          name="firstName"
                          placeholder={
                            getLanguageFile(state.language).JobApply.firstName
                          }
                          ref={register({
                            required: true,
                          })}
                        />
                        {errors.firstName && (
                          <p>
                            {
                              getLanguageFile(state.language).JobApply
                                .isRequired
                            }
                          </p>
                        )}
                      </Col>
                      <Col>
                        <Form.Control
                          name="lastName"
                          placeholder={
                            getLanguageFile(state.language).JobApply.lastName
                          }
                          ref={register({
                            required: true,
                          })}
                        />
                        {errors.lastName && (
                          <p>
                            {
                              getLanguageFile(state.language).JobApply
                                .isRequired
                            }
                          </p>
                        )}
                      </Col>
                    </Form.Row>
                    <Form.Control
                      placeholder={
                        getLanguageFile(state.language).JobApply.email
                      }
                      name="email"
                      id="Email"
                      ref={register({
                        required: true,
                        pattern: /.+@.+\.[A-Za-z]+$/,
                      })}
                    />

                    {errors.email?.type == "required" && (
                      <p>
                        {getLanguageFile(state.language).JobApply.isRequired}
                      </p>
                    )}
                    {errors.email?.type == "pattern" && (
                      <p>
                        {getLanguageFile(state.language).JobApply.invalidEmail}
                      </p>
                    )}

                    <Form.Control
                      placeholder={
                        getLanguageFile(state.language).JobApply.dateOfBirth
                      }
                      name="dateOfBirth"
                      id="date"
                      ref={register({
                        required: true,
                        pattern: new RegExp(
                          "^((0)[0-9]|(1)[0-9]|(2)[0-9]|(3)[0-1])/((0)[0-9]|(1)[0-2])/((19)[0-9][0-9]|(20)[0-1][0-9]|(2020))$"
                        ),
                      })}
                    />

                    {errors.date?.type == "required" && (
                      <p>
                        {getLanguageFile(state.language).JobApply.isRequired}
                      </p>
                    )}
                    {errors.date?.type == "pattern" && (
                      <p>
                        {getLanguageFile(state.language).JobApply.invalidDate}
                      </p>
                    )}

                    <Form.Control
                      name="city"
                      placeholder={
                        getLanguageFile(state.language).JobApply.city
                      }
                      ref={register({
                        required: true,
                      })}
                    />
                    {errors.city && (
                      <p>
                        {getLanguageFile(state.language).JobApply.isRequired}
                      </p>
                    )}
                    <Form.Control
                      name="personalWebsite"
                      placeholder={
                        getLanguageFile(state.language).JobApply.personalWebsite
                      }
                      ref={register({
                        pattern: isUrlRegex,
                      })}
                    />
                    {errors.personalWebsite?.type === "pattern" && (
                      <p>
                        {getLanguageFile(state.language).JobApply.invalidUrl}
                      </p>
                    )}
                    <Form.Control
                      name="twitter"
                      placeholder={
                        getLanguageFile(state.language).JobApply.twitter
                      }
                      ref={register({
                        pattern: isUrlRegex,
                      })}
                    />
                    {errors.twitter?.type === "pattern" && (
                      <p>
                        {getLanguageFile(state.language).JobApply.invalidUrl}
                      </p>
                    )}
                    <Form.Control
                      name="linkedIn"
                      placeholder={
                        getLanguageFile(state.language).JobApply.linkedIn
                      }
                      ref={register({
                        pattern: isUrlRegex,
                      })}
                    />
                    {errors.linkedIn?.type === "pattern" && (
                      <p>
                        {getLanguageFile(state.language).JobApply.invalidUrl}
                      </p>
                    )}
                    <Form.Control
                      name="facebook"
                      placeholder={
                        getLanguageFile(state.language).JobApply.facebook
                      }
                      ref={register({
                        pattern: isUrlRegex,
                      })}
                    />
                    {errors.facebook?.type === "pattern" && (
                      <p>
                        {getLanguageFile(state.language).JobApply.invalidUrl}
                      </p>
                    )}
                    <Form.Control
                      name="education"
                      placeholder={
                        getLanguageFile(state.language).JobApply.education
                      }
                      ref={register({
                        required: true,
                      })}
                      as="textarea"
                      rows="5"
                    />
                    {errors.education && (
                      <p>
                        {getLanguageFile(state.language).JobApply.isRequired}
                      </p>
                    )}
                    <Form.Control
                      name="experience"
                      placeholder={
                        getLanguageFile(state.language).JobApply.experience
                      }
                      ref={register({
                        required: true,
                      })}
                      as="textarea"
                      rows="5"
                    />
                    {errors.experience && (
                      <p>
                        {getLanguageFile(state.language).JobApply.isRequired}
                      </p>
                    )}
                    <FormFile
                      htmlFor="uploadBtn"
                      name="file"
                      className="form-file"
                    >
                      <div className="choose-file-container">
                        {selectedFileList.length !== 0 ? (
                          <div className="selected-file-container">
                            <img
                              className="upload-file-image"
                              src={UPLOAD_IMG}
                            />
                            <h6>{selectedFileListTag}</h6>
                            <button
                              onClick={() => {
                                setSelectedFileList([])
                                setSelectedFileListTag("")
                              }}
                              className="remove-file-button"
                            >
                              <img src={REMOVE_FILE} />
                            </button>
                          </div>
                        ) : null}
                        <div>
                          <h6 id="file-text">
                            {getLanguageFile(state.language).JobApply.portfolio}
                          </h6>
                          <label
                            className="custom-button choose-file-text"
                            htmlFor="uploadBtn"
                          >
                            {
                              getLanguageFile(state.language).JobApply
                                .orSelectFile
                            }
                          </label>
                        </div>

                        <input
                          id="uploadBtn"
                          type="file"
                          className="uploadInput"
                          accept=".pdf, .docx, .docs"
                          onChange={onFileUpload}
                          multiple
                        />
                      </div>
                    </FormFile>
                    {selectedFileList.length === 0 && isUpload && (
                      <p>
                        {getLanguageFile(state.language).JobApply.isRequired}
                      </p>
                    )}
                    <div className="container">
                      <div className="checkbox">
                        <input
                          ref={register({
                            required: true,
                          })}
                          name="clarificationText"
                          type="checkbox"
                          id="checkbox"
                        />
                        <label htmlFor="checkbox">
                          {renderClarificaitonText()}
                        </label>
                      </div>
                    </div>
                    {errors.clarificationText && (
                      <p className="checkbox-warning">
                        {getLanguageFile(state.language).JobApply.isRequired}
                      </p>
                    )}
                    <Col className="custom-button-container">
                      <Button
                        type="submit"
                        onClick={() => setIsUpload(!isUpload)}
                      >
                        {getLanguageFile(state.language).JobApply.apply}
                      </Button>
                    </Col>
                  </Form>
                </Col>
              </Container>
            </Container>
          )}
        </Col>
        <Footer />
      </Container>
      <ClarificaitonModal
        showModal={showModal}
        onHide={() => setShowModal(false)}
      />
    </>
  )
}

JobApplyPage.propTypes = {
  location: PropTypes.string,
}

export default JobApplyPage
